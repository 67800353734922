export function saveLoginEmail(email: string) {
  localStorage.setItem("login-email", email);
}

export function loadLoginEmail(): string {
  return localStorage.getItem("login-email") || "";
}

export function loadSession(): string {
  return localStorage.getItem("session") || "";
}

export function login(session: string) {
  console.log("logging in, session:", session);
  localStorage.setItem("session", session);
  document.cookie = "logged_in=true";
  window.location.href = "/";
}

export function logout() {
  console.log("logging out");
  localStorage.setItem("session", "");
  document.cookie = "logged_in=false,SameSite=Strict";
}
