import {Elm} from "./Passport.elm";
import {loadLoginEmail, login, saveLoginEmail} from "~/lib/ts_lib/helpers";

let app = Elm.Passport.init({
  flags: {theme: "light", email: loadLoginEmail()}
});

app.ports.outboxSaveEmail.subscribe(function (email) {
  saveLoginEmail(email);
});

// app.ports.login.subscribe(function (email) {
//   login(email);
// });
