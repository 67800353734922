module Passport exposing (main)

import Browser exposing (Document, UrlRequest(..))
import Browser.Navigation as Navigation
import Html exposing (Html)
import Html.Attributes as HA
import Passport.HomePage as HomePage
import Passport.LoginPage as LoginPage
import Passport.SignupPage as SignupPage
import Theme.Theme as Theme exposing (Theme(..))
import Url exposing (Url)


main : Program Flags Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlChange = ChangedUrl
        , onUrlRequest = ClickedLink
        }


type alias Model =
    { navKey : Navigation.Key
    , theme : Theme
    , page : Page
    }


type Page
    = HomePage HomePage.Model
    | LoginPage LoginPage.Model
    | SignupPage SignupPage.Model


type Msg
    = ChangedUrl Url
    | ClickedLink UrlRequest
    | LoginMsg LoginPage.Msg
    | SignupMsg SignupPage.Msg
    | HomeMsg HomePage.Msg
    | Noop


type alias Flags =
    { theme : String
    , email : String
    }


init : Flags -> Url -> Navigation.Key -> ( Model, Cmd Msg )
init flags url navKey =
    let
        model : Model
        model =
            { navKey = navKey
            , theme = Theme.fromString flags.theme
            , page = LoginPage <| LoginPage.init flags.email
            }
    in
    changedUrl url model


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        Noop ->
            ( model, Cmd.none )

        ChangedUrl url ->
            changedUrl url model

        ClickedLink req ->
            case req of
                Internal url ->
                    ( model, clickedLink url model.navKey )

                External url ->
                    ( model, Navigation.load url )

        LoginMsg loginMsg ->
            updateLoginPage model loginMsg

        SignupMsg signupMsg ->
            updateSignupPage model signupMsg

        HomeMsg homeMsg ->
            updateHomePage model homeMsg


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.none


view : Model -> Document Msg
view model =
    { title = "Allude"
    , body =
        [ Html.div
            [ HA.class "passport"
            , Theme.attr model.theme
            ]
            [ case model.page of
                HomePage homeModel ->
                    HomePage.view homeModel

                LoginPage loginModel ->
                    LoginPage.view loginModel |> Html.map LoginMsg

                SignupPage signupModel ->
                    SignupPage.view signupModel |> Html.map SignupMsg
            ]
        ]
    }


changedUrl : Url -> Model -> ( Model, Cmd Msg )
changedUrl url model =
    let
        email =
            getEmail model

        ( page, cmd ) =
            if "/" == url.path then
                HomePage.init email
                    |> Tuple.mapBoth HomePage (Cmd.map HomeMsg)

            else if LoginPage.url == url.path then
                ( LoginPage <| LoginPage.init email, Cmd.none )

            else if SignupPage.url == url.path then
                ( SignupPage <| SignupPage.init email, Cmd.none )

            else
                ( model.page, Cmd.none )
    in
    ( { model | page = page }, cmd )


clickedLink : Url -> Navigation.Key -> Cmd Msg
clickedLink url navKey =
    if url.path == "/" then
        Navigation.load url.path

    else if List.member url.path [ LoginPage.url, SignupPage.url ] then
        Navigation.pushUrl navKey url.path

    else
        Cmd.none


updateLoginPage model msg =
    case model.page of
        LoginPage loginModel ->
            LoginPage.update msg loginModel
                |> Tuple.mapBoth
                    (\page -> { model | page = LoginPage page })
                    (Cmd.map LoginMsg)

        _ ->
            ( model, Cmd.none )


updateSignupPage model msg =
    case model.page of
        SignupPage loginModel ->
            SignupPage.update msg loginModel
                |> Tuple.mapBoth
                    (\page -> { model | page = SignupPage page })
                    (Cmd.map SignupMsg)

        _ ->
            ( model, Cmd.none )


updateHomePage model msg =
    case model.page of
        HomePage loginModel ->
            HomePage.update msg loginModel
                |> Tuple.mapBoth
                    (\page -> { model | page = HomePage page })
                    (Cmd.map HomeMsg)

        _ ->
            ( model, Cmd.none )


getEmail : Model -> String
getEmail model =
    case model.page of
        HomePage { email } ->
            email

        LoginPage { email } ->
            email

        SignupPage { email } ->
            email
